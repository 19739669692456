import { gql } from '@apollo/client'

const GET_EVENT_FOR_PREREG = gql`
  query GET_EVENT_FOR_PREREG($id: ID!) {
    event(id: $id) {
      id
      title
      content
      newEventForm
      images {
        id
        filename
        type
      }
    }
  }
`

export default GET_EVENT_FOR_PREREG
