import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

/**
 * @deprecated Use LogType from server/modules/logging/logServerSideTransaction.ts
 */
enum LogType {
  Success = 0,
  UserError = 1,
  SystemError = 2,
}

/**
 * @deprecated Use ServerSideTransactionLog from server/modules/logging/logServerSideTransaction.ts
 */
type TransactionalLog = {
  message: string
  logType: LogType
  attributes?: Array<string>
  actionTypes: Array<string>
  errorDependency?: string
}

/**
 *
 * @deprecated Use logServerSideTransaction from server/modules/logging/logServerSideTransaction.ts
 */
const logTransaction = (log: TransactionalLog) => {
  if (typeof window === undefined) {
    return
  }
  if (!window?.navigator?.sendBeacon) {
    return
  }

  const blob = new Blob([JSON.stringify(createTransactionalLogRequest(log))], {
    type: 'application/json',
  })

  window.navigator.sendBeacon(publicRuntimeConfig.TRANSACTION_LOGGING_URL, blob)
}

const createTransactionalLogRequest = (log: TransactionalLog) => ({
  ...log,
  logType: mapLogTypeToText(log.logType),
  app: 'Website',
})

const logTypeToTextMap = {
  [LogType.Success]: 'Success',
  [LogType.UserError]: 'UserError',
  [LogType.SystemError]: 'SystemError',
}

const mapLogTypeToText = (logType: LogType) => {
  // converting log type enum to string ra-web understands
  if (logType in logTypeToTextMap) {
    return logTypeToTextMap[logType]
  }

  return 'Unknown'
}

export { logTransaction, LogType }
export type { TransactionalLog }
