import isEmpty from 'lodash/isEmpty'

interface QueryParameters {
  [key: string]: string | string[]
}

// Converts next/router query to query string
// This won't return arrays of values as its not universally supported therefore unwise to use with catch all routes
// https://nextjs.org/docs/routing/dynamic-routes#catch-all-routes
const parseQueryParameters = (
  startsWith: '?' | '&',
  parameters: QueryParameters
) => {
  if (!parameters || isEmpty(parameters)) {
    return ''
  }
  const entries = Object.entries(parameters).map(([key, value]) => {
    if (Array.isArray(value)) {
      // selecting the first value in the array
      return [key, value[0]]
    }
    return [key, value]
  })

  const urlSearchParametrs = new URLSearchParams(entries)

  return `${startsWith}${urlSearchParametrs.toString()}`
}

const createUrlWithQueryParameters = (
  url: string,
  join: '?' | '&',
  parameters: QueryParameters
) => {
  return `${url}${parseQueryParameters(join, parameters)}`
}

export { parseQueryParameters, createUrlWithQueryParameters }
