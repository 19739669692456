import { useQuery } from '@apollo/client'
import QueryResultHandler from 'components/generic/query-result-handler'
import useSeo from 'hooks/useSeo'
import Seo from 'components/generic/seo'
import Header from 'components/generic/header'
import Iframe from 'components/shared/iframe'
import testIds from 'enums/testIds'
import { createUrlWithQueryParameters } from 'lib/queryParameters'
import getConfig from 'next/config'
import Throw500 from 'components/generic/throw-500'
import Throw404 from 'components/generic/throw-404'
import ENTITY_TYPE from 'enums/entity-type'
import IMAGE_TYPE from 'enums/image-type'
import getEventImage from 'lib/getEventImage'
import ImageDto from 'interfaces/gql/ImageDto'
import { useRouter } from 'next/router'
import GET_EVENT_FOR_PREREG from './GetEventForPreRegQuery'

const {
  publicRuntimeConfig: { LEGACY_IFRAME_BASE_PATH },
} = getConfig()

const PreRegPage = ({ eventId }: { eventId: string }) => {
  const { data, loading, error } = useQuery(GET_EVENT_FOR_PREREG, {
    variables: {
      id: eventId,
    },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="event"
      handleError={() => <Throw500 />}
      handleEmpty={() => <Throw404 entityType={ENTITY_TYPE.PreReg} />}
      markup={PreRegPageMarkup}
    />
  )
}

interface PreRegPageMarkupProps {
  data: {
    id: string
    title: string
    content: string
    images: ImageDto[]
  }
}

const PreRegPageMarkup = ({ data }: PreRegPageMarkupProps) => {
  const { query } = useRouter()
  const seoData = useSeo({
    overrides: {
      title: data.title,
      description: data.content,
      images: data.images?.map((image) => ({
        url: image.filename,
        alt: data.title,
      })),
    },
  })
  const { id, title } = data

  const flyerFrontUrl = getEventImage(data, IMAGE_TYPE.flyerFront)

  const baseUrl = `${LEGACY_IFRAME_BASE_PATH || ''}/legacy-pre/${id}`
  const parameters = { iframe: 'true', ...query }
  const url = createUrlWithQueryParameters(baseUrl, '?', parameters)

  return (
    <>
      <Seo {...seoData} />
      <Header imageUrl={flyerFrontUrl} text={title} blur />
      <Iframe title={title} url={url} dataTestId={testIds.preReg} />
    </>
  )
}

export { PreRegPageMarkup }
export default PreRegPage
